import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { brokerDealerBiddingActions } from '../../../../actions';
import { Rating } from '../../../../types/enums/Rating';
import { Popup, PopupBody, PopupFooter } from '../../../controls';
import { Pluralize } from "../../../common";
import { AppState } from '../../../../types/state/AppState';
import { apiUtils, biddingUtils, formatUtils, moneyUtils } from '../../../../utils';
import { BidLevelDelta } from '../../../common/bidding/BidLevelDelta';
import { ApiOperationType } from '../../../../types/api-operation/ApiOperationType';
import { RequestState } from '../../../../constants/request-state';
import { BidsConfirmationState } from '../../../../types/state/BrokerDealerBiddingState';
import { useAgreement } from '../../../seller-buyside/useAgreement';
import { IColumnDefinition } from '../../common/table/types/ColumnDefinition';
import { ColumnBuilder } from '../../common/table/columns/column-builder/ColumnBuilder';
import { Table } from '../../common/table';
import { SubmitBidStatus } from './SubmitBidStatus';
import { ReSubmitBidButton } from './ReSubmitBidButton';
import { keys } from 'lodash';
import { isActiveTrade } from '../../../../types/trades/TradeStatus';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';
import { bidConfirmationActions } from '../../../../actions/bid-confirmation.actions';
import { StatusMessageSectionType } from "../../../../types/state/NotificationState";
import { StatusMessageSection } from "../../../status-message/StatusMessageSection";
import { OpenBiddingStatus } from '../../../../types/enums/OpenBiddingStatus';
import { BwicProcessType } from '../../../../types/models/Process';
import { ListBuilder } from '../../../../utils/ListBuilder';
import { constants } from '../../../../constants/constants';
import { AxedIcon } from '../../common/AxedIcon';
import { FinalIcon } from '../../common/FinalIcon';
import { InfoTooltip } from '../../../common/InfoTooltip';

export function ConfirmBidsPopup() {
    const visible = useSelector((s: AppState) => s.brokerDealerBidding.submitBidsConfirmation.submitBidsConfirmationVisible);
    const securityToConfirmLength = useSelector((s: AppState) => s.brokerDealerBidding.submitBidsConfirmation.securities?.length);

    return visible && securityToConfirmLength ? <ConfirmBidsPopupContent /> : null;
}

function ConfirmBidsPopupContent() {
    const dispatch = useDispatch();

    const [bids, setBids] = React.useState<BidsConfirmationState>({});
    const [confirmPressed, setConfirmPressed] = React.useState(false);

    const bwic = useSelector((s: AppState) => s.bidding.bwic);
    const allSecurities = useSelector((s: AppState) => s.brokerDealerBidding.securities);
    const confirmingSecurities = useSelector((s: AppState) => s.brokerDealerBidding.submitBidsConfirmation.securities);
    const securities = allSecurities.filter(s => confirmingSecurities?.some(id => s.id === id));
    const submitInProgress = useSelector((s: AppState) => s.brokerDealerBidding.submitBidsConfirmation.submitInProgress);
    const hasPendingApiOperations = useSelector((s: AppState) =>
        s.apiOperation.requests.some(r =>
            r.state === RequestState.request && (
                r.event === ApiOperationType.SubmitBidRequest || // all to all bids
                r.event === ApiOperationType.SubmitAxedFinal ||
                r.event === ApiOperationType.SubmitBid)
        )
    );
    const editBidState = useSelector((s: AppState) => s.brokerDealerBidding.editBid);
    const submitResults = useSelector((s: AppState) => !submitInProgress && !hasPendingApiOperations
        ? s.brokerDealerBidding.submitBidsConfirmation.submitResult
        : undefined
    );
    const { agreement } = useAgreement();

    React.useEffect(() => {
        if (!keys(bids).length && securities.length) {
            const positionBids = apiUtils.normalize(
                securities,
                position => position.id,
                position => ({
                    bid: editBidState[position.id],
                    currentBid: biddingUtils.getCurrentBid(position.bids)
                })
            );
            setBids(positionBids);
        }
    }, [bids, securities, editBidState]);

    if (!keys(bids).length) return null;

    const allPositionsTraded = !securities.some(s => !isActiveTrade(s.trade) && !s.isTradedAway);
    const isSubmitting = submitInProgress || hasPendingApiOperations;
    const isOpenBidingEnded =
        bwic.process.type === BwicProcessType.Live &&
        bwic.process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded;

    const getTitle = () => <><Pluralize template="{{text}}" singular="Bid" count={securities.length} /> Confirmation</>

    const handleSubmit = () => {
        setConfirmPressed(true);
        dispatch(brokerDealerBiddingActions.submitBids(undefined, bids));
    }

    const handleClose = () => {
        if (!isSubmitting) {
            dispatch(bidConfirmationActions.reset());
        }
    }

    const renderGeneralNotification = () => {
        return (
            <StatusMessageSection type={StatusMessageSectionType.Alert}>
                A bid cannot be revoked or decreased;<br />
                Bid or latest bid must remain executable for 30 minutes beyond the end of the bidding window.
                <div className="text-medium">Seller will be notified if a bidder is bidding over themselves, but the KTX ATS platform does not enforce trading at the prior level.</div>
            </StatusMessageSection>
        );
    }

    const getColumns = () => {
        const isipCusip: IColumnDefinition<BwicPosition> = {
            columnKey: 'isinCusip',
            renderColumnHeaderContent: () => 'Identifier',
            renderColumnContent: position => position.isinCusip,
            className: 'data-list-cell-sm'
        };
        const ticker: IColumnDefinition<BwicPosition> = {
            columnKey: 'ticker',
            renderColumnHeaderContent: () => 'Ticker',
            renderColumnContent: position => position.ticker,
            className: 'data-list-cell-lg'
        };
        const bidSize: IColumnDefinition<BwicPosition> = {
            columnKey: 'bid-size',
            renderColumnHeaderContent: () => 'Bid Size',
            renderColumnContent: position => moneyUtils.money(position.size),
            className: 'text-right'
        };
        const bid: IColumnDefinition<BwicPosition> = {
            columnKey: 'bid',
            renderColumnHeaderContent: () => 'Bid',
            renderColumnContent: position => renderBid(position),
            className: 'data-list-cell-lg cell-bid-level'
        };
        const axed: IColumnDefinition<BwicPosition> = {
            columnKey: 'axed',
            renderColumnHeaderContent: () => '',
            renderColumnContent: position => (
                <>
                    {
                        Boolean(bids[position.id]?.bid.axed ?? bids[position.id]?.currentBid?.axed) &&
                        <AxedIcon />
                    }
                </>
            ),
            className: 'data-list-cell-xxxs padding-l-0 text-center'
        };
        const final: IColumnDefinition<BwicPosition> = {
            columnKey: 'final',
            renderColumnHeaderContent: () => '',
            renderColumnContent: position => (
                <>
                    {
                        Boolean(bids[position.id]?.bid.final ?? bids[position.id]?.currentBid?.final) &&
                        <FinalIcon />
                    }
                </>
            ),
            className: 'data-list-cell-xxxs padding-l-0 text-center'
        };
        const commission: IColumnDefinition<BwicPosition> = {
            columnKey: 'commission',
            renderColumnContent: position =>
                <Commission
                    editingCommission={bids[position.id]?.bid?.commission}
                    rating={position.rating}
                    currentBidCommission={bids[position.id]?.currentBid?.commission}
                />,
            renderColumnHeaderContent: () => 'Comm, %',
            className: 'data-list-cell-xs text-right'
        };
        const total: IColumnDefinition<BwicPosition> = {
            columnKey: 'total',
            renderColumnContent: renderTotal,
            renderColumnHeaderContent: () => <>Final Bid<InfoTooltip text="Final bid includes commission." /></>,
            headerClassName: 'text-right',
            bodyClassName: 'text-right text-bold'
        };
        const submitStatus: IColumnDefinition<BwicPosition> = {
            columnKey: 'submit-status',
            renderColumnHeaderContent: () => 'Status',
            renderColumnContent: position =>
                <SubmitBidStatus
                    position={position}
                    isPass={Boolean(bids[position.id]?.bid.pass)}
                    isColorDistribution={bwic.isColorDistribution}
                    isOpenBiddingEnded={isOpenBidingEnded}
                />,
            className: 'data-list-cell-xl cell-submit-status'
        };
        const actionButton: IColumnDefinition<BwicPosition> = {
            columnKey: 'action-button',
            renderColumnHeaderContent: () => '',
            renderColumnContent: position =>
                <ReSubmitBidButton
                    position={position}
                    bid={bids[position.id]}
                    isColorDistribution={bwic.isColorDistribution}
                    isOpenBiddingEnded={isOpenBidingEnded}
                />,
            className: 'data-list-cell-sm text-right'
        };

        const columns = new ListBuilder<IColumnDefinition<BwicPosition>>()
            .add(isipCusip, ticker, bidSize, bid)
            .addWhen(() => bwic.isAllToAll, commission, total)
            .add(axed, final, submitStatus, actionButton)
            .result()

        return columns.map(c => new ColumnBuilder(c));
    }

    const renderBid = (position: BwicPosition) => {
        const { bid, currentBid } = bids[position.id];

        if (bid.pass) {
            return 'Pass';
        }

        if (bid.value) {
            return <BidLevelDelta currentLevel={currentBid?.value ?? 0} updatedLevel={+bid.value} />;
        }

        return formatUtils.formatBid(currentBid?.value)
    }

    const renderTotal = (position: BwicPosition) => {
        const { bid, currentBid } = bids[position.id];
        const bidLevel = bid?.value ?? currentBid?.value;
        const commisson = 
            bid.commission || 
            currentBid?.commission || 
            agreement?.commissions[position.rating];

        return formatUtils.formatBid(Number(bidLevel) + Number(commisson));
    }

    const renderBody = () => {
        return (
            <>
                {renderGeneralNotification()}
                <Table
                    className="data-list-striped"
                    dataItems={securities}
                    columns={getColumns()}
                    createSecurityCustomClassName={
                        (position: BwicPosition) => cn({
                            'tradedaway':
                                bwic.isColorDistribution ||
                                position.isTradedAway ||
                                position.trade || (
                                    submitResults && (
                                        submitResults[position.id]?.validationError ||
                                        submitResults[position.id]?.stageTransitionConflict
                                    )
                                )
                        })
                    }
                />
            </>
        );
    }

    const renderButtons = () => {
        if (confirmPressed || bwic.isColorDistribution || allPositionsTraded) {
            return <button type="button" className="btn btn-ghost" disabled={isSubmitting} onClick={handleClose}>Close</button>
        }

        return (
            <>
                <button type="button" className="btn btn-ghost" onClick={handleClose}>Cancel</button>
                <button
                    type="button"
                    className="btn btn-main"
                    onClick={handleSubmit}
                >
                    Confirm
                </button>
            </>
        );
    }

    return (
        <Popup
            title={getTitle()}
            modalClass={bwic.isAllToAll ? "confirm-buyer-bids-popup" : "confirm-bids-popup"}
            onClose={handleClose}
            disabled={isSubmitting}
        >
            <PopupBody>{renderBody()}</PopupBody>
            <PopupFooter className="right-padding">{renderButtons()}</PopupFooter>
        </Popup>
    );
}

interface CommissionProps {
    editingCommission?: string;
    currentBidCommission?: number;
    rating: Rating;
}

function Commission({ editingCommission, currentBidCommission, rating }: CommissionProps) {
    const { agreement } = useAgreement();
    const commission = editingCommission ?? currentBidCommission ?? agreement?.commissions[rating];

    if (!commission) return null;

    return <>{Number(commission).toFixed(constants.commissionDecimalPlaces)}</>;
}

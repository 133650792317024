import * as React from 'react';
import {  useSelector } from 'react-redux';
import classNames from 'classnames';
import { keys } from 'lodash';
import TextareaAutosize from 'react-textarea-autosize';
import { Popup, PopupBody, PopupFooter, FormError } from '../../../controls';
import { Confirm } from '../../../alerts';
import { sellerBiddingActions } from '../../../../actions';
import { formatUtils, moneyUtils, biddingUtils, arrayUtils } from '../../../../utils';
import IconSVG from '../../../../styles/svg-icons';
import { OnHoverTooltip } from '../../../common';
import { getSecuritiesWithLatestBids, tradingPositionsSelector } from '../../../../selectors';
import { AppState } from '../../../../types/state/AppState';
import { IColumnDefinition } from '../../common/table/types/ColumnDefinition';
import { BwicPosition } from '../../../../types/bwic/BwicPosition';
import { TraidingPositionState } from '../../../../types/state/SellerBiddingState';
import { ColumnBuilder } from '../../common/table/columns/column-builder/ColumnBuilder';
import { Table } from '../../common/table';
import { Bid } from '../../../../types/bidding/Bid';
import { TiedForBestWarningIcon } from './TiedForBestWarningIcon';
import { DropDownList, DropDownListItem } from '../../../controls/DropDownList';
import { Checkbox } from '../../../controls/Checkbox';
import { BidOverThemselfIcon } from '../biddingSecurities/BidOverThemselfIcon';
import { useAppDispatch } from '../../../../effects/useAppDispatch';
import { DayPickerInput } from '../../../controls/DayPickerInput';

interface Props {
    tradingPositions: {
        [positionId: number]: TraidingPositionState
    };
}

function TradePopupContent({ tradingPositions }: Props) {
    const dispatch = useAppDispatch();

    const positions = useSelector((s: AppState) => tradingPositionsSelector(s.sellerBidding));
    const holidays = useSelector((s: AppState) => s.sellerBidding.holidays);
    const tradeAllChecked = useSelector((s: AppState) => s.sellerBidding.tradeAllChecked);
    const tradingConfirmationVisible = useSelector((s: AppState) => s.sellerBidding.tradingConfirmationVisible);
    const minimumSettleDate = useSelector((s: AppState) => s.sellerBidding.minimumSettleDate);

    const hasSelectedPositions = React.useMemo(
        () => keys(tradingPositions).some(positionId => tradingPositions[+positionId]?.selected),
        [tradingPositions]
    );

    const canSelect = positions.length > 1;

    const handleClose = () => {
        dispatch(sellerBiddingActions.cancelTrade());
        dispatch(sellerBiddingActions.tradeAllSelectionChange(false));
    }

    const handleConfirmTraded = () => {
        dispatch(sellerBiddingActions.submitTrade());
        dispatch(sellerBiddingActions.tradeAllSelectionChange(false));
    }

    const getColumns = () => {
        const columns: IColumnDefinition<BwicPosition>[] = [
            {
                columnKey: 'onBehalf',
                renderColumnHeaderContent: () => '',
                renderColumnContent: position => {
                    const tradingState = tradingPositions[position.id];
                    const isOffPlatformCompanySelected = position.bids.some(b =>
                        b.onBehalf &&
                        biddingUtils.getBidCompanyIdentifier(b) === tradingState?.tradingCompanyIdentifier
                    );

                    return (
                        isOffPlatformCompanySelected &&
                        <OnHoverTooltip overlay="Bid on behalf will be automatically confirmed.">
                            <span><IconSVG name="bid-on-behalf" width={16} height={16} /></span>
                        </OnHoverTooltip>
                    );
                },
                headerClassName: 'data-list-cell-xxs',
                bodyClassName: 'data-list-cell-xxs',
            },
            {
                columnKey: 'position-check',
                renderColumnHeaderContent: () => {
                    const tradingPositionIdList = Object.keys(tradingPositions).map(positionId => +positionId);

                    return (
                        <>
                            {
                                canSelect &&
                                <Checkbox
                                    label="Identifier"
                                    onChange={e => {
                                        e.stopPropagation();
                                        dispatch(sellerBiddingActions.tradeAllSelectionChange(!tradeAllChecked));
                                    }}
                                    checked={tradeAllChecked || !canSelect}
                                    disabled={!canSelect}
                                    partially={
                                        tradingPositionIdList.some(id => tradingPositions[id].selected) &&
                                        tradingPositionIdList.some(id => !tradingPositions[id].selected)
                                    }
                                />
                            }
                            {
                                !canSelect && <div className="no-checkbox-label">Identifier</div>
                            }
                        </>
                    );
                },
                renderColumnContent: position => {
                    const tradingState = tradingPositions[position.id];
                    return (
                        <Checkbox
                            label={position.isinCusip}
                            onChange={(e) => {
                                e.stopPropagation();
                                dispatch(sellerBiddingActions.tradingPositionSelectionChange(position.id, !tradingState?.selected))
                            }}
                            checked={tradingState?.selected}
                            disabled={!canSelect}
                        />
                    );
                },
                headerClassName: 'data-list-cell-md',
                bodyClassName: 'data-list-cell-md',
            },
            {
                columnKey: 'ticker',
                renderColumnHeaderContent: () => 'Ticker',
                renderColumnContent: position => position.ticker,
                headerClassName: 'data-list-cell-lg',
                bodyClassName: 'data-list-cell-lg',
            },
            {
                columnKey: 'rating',
                renderColumnHeaderContent: () => 'Rtg',
                renderColumnContent: position => position.rating,
                headerClassName: 'data-list-cell-xxs',
                bodyClassName: 'data-list-cell-xxs',
            },
            {
                columnKey: 'settleDate',
                renderColumnHeaderContent: () => 'Settle Date',
                renderColumnContent: position => renderSettleDate(position, tradingPositions[position.id]),
                headerClassName: 'data-list-cell-md cell-input cell-settle overflow-visible',
                bodyClassName: 'data-list-cell-md cell-input cell-settle overflow-visible',
            },
            {
                columnKey: 'counterparty',
                renderColumnHeaderContent: () => 'Counterparty',
                renderColumnContent: position => renderCompanyList(position, tradingPositions[position.id]),
                headerClassName: 'data-list-cell-sm cell-input cell-counterparty overflow-visible',
                bodyClassName: 'data-list-cell-sm cell-input cell-counterparty overflow-visible'
            },
            {
                columnKey: 'tiedForBestWarning',
                renderColumnHeaderContent: () => '',
                renderColumnContent: position => <TiedForBestWarningIcon position={position} />,
                headerClassName: 'data-list-cell-xxs padding-l-0 text-center',
                bodyClassName: 'data-list-cell-xxs padding-l-0 text-center',
            },
            {
                columnKey: 'price',
                renderColumnHeaderContent: () => 'Price',
                renderColumnContent: position => renderTradingCompanyBids(position, tradingPositions[position.id]),
                headerClassName: 'data-list-cell-md cell-input overflow-visible',
                bodyClassName: 'data-list-cell-md cell-input overflow-visible'
            },
            {
                columnKey: 'bidOverThemselfWarning',
                renderColumnHeaderContent: () => '',
                renderColumnContent: position =>
                    <BidOverThemselfIcon
                        companyIdentifier={tradingPositions[position.id]?.tradingCompanyIdentifier}
                        bids={position.bids}
                        onHoverTooltipMessage="Two best bids are placed by the same bidder. Both prices are provided in the Price column drop-down. Consider trading at the lower level to prevent “bidding over themselves”."
                    />,
                headerClassName: 'data-list-cell-xxs padding-l-0 text-center',
                bodyClassName: 'data-list-cell-xxs padding-l-0 text-center',
            },
            {
                columnKey: 'size',
                renderColumnHeaderContent: () => 'Size',
                renderColumnContent: position =>
                    <input
                        type="text"
                        className="form-control"
                        value={moneyUtils.money(position.size)}
                        disabled={true}
                    />,
                headerClassName: 'data-list-cell-sm cell-input text-right',
                bodyClassName: 'data-list-cell-sm cell-input text-right',
            },
            {
                columnKey: 'comment',
                renderColumnHeaderContent: () => 'Comments',
                renderColumnContent: position => {
                    const tradingState = tradingPositions[position.id];
                    return (
                        <TextareaAutosize
                            minRows={1}
                            maxRows={4}
                            maxLength={144}
                            className="form-control"
                            placeholder="Add comment"
                            value={tradingState.comment || ''}
                            onChange={e => dispatch(sellerBiddingActions.tradingPositionCommentChange(position.id, e.target.value))}
                            onClick={(e) => e.stopPropagation()}
                        />
                    );
                },
                headerClassName: 'data-list-cell-xl-flexible cell-comment',
                bodyClassName: 'data-list-cell-xl-flexible cell-comment',
            }
        ];

        return columns.map(c => new ColumnBuilder(c));
    }

    const renderSettleDate = (position: BwicPosition, tradingState?: TraidingPositionState) => {
        const error = tradingState?.errors?.settleDate;
        return (
            <div
                className="form-control-wrapper"
                onClick={e => e.stopPropagation()}
            >
                <DayPickerInput
                    className={classNames('form-control form-control-sm', { 'is-invalid': error })}
                    onDayChange={(day) => dispatch(sellerBiddingActions.tradingPositionSettleDateChange(position.id, day))}
                    minimumDate={minimumSettleDate}
                    selectedDate={tradingState?.settleDate}
                    allowWeekends={false}
                    disabledDays={holidays?.map(h => new Date(h))}
                />
                {!!error && <FormError message={error} />}
            </div>
        );
    }

    const renderCompanyList = (position: BwicPosition, tradingState?: TraidingPositionState) => {
        const [positionWithLatestBids] = getSecuritiesWithLatestBids({ securities: [position] });
        const [best] = biddingUtils.groupBidsByLevel(positionWithLatestBids.bids);

        const tradingCompanies = positionWithLatestBids.bids
            .filter(b => !b.pass)
            .sort((a, b) => +b.value - +a.value)
            .map(b => ({
                key: biddingUtils.getBidCompanyIdentifier(b),
                title: b.buyerPseudoOrderNumber
                    ? `${b.company.code}-${b.buyerPseudoOrderNumber}`
                    : b.company.code
            }));

        const handleTradingCompanyChange = (item: DropDownListItem, e: React.MouseEvent) => {
            e.stopPropagation();
            dispatch(sellerBiddingActions.tradingPositionCompanyChange(position.id, item.value));
        };

        const renderItem = (item: DropDownListItem) => {
            const isTiedForBest = best?.length > 1 && best.some(b => biddingUtils.getBidCompanyIdentifier(b) === item.value);
            return (
                <>
                    {item.text} {isTiedForBest && <span>(tied for best)</span>}
                </>
            );
        };

        return (
            <DropDownList
                className="form-control-sm"
                items={tradingCompanies.map(c => ({
                    value: c.key,
                    text: c.title,
                    selected: c.key === tradingState?.tradingCompanyIdentifier,
                }))}
                defaultSortingEnabled={false}
                renderItemContentCallback={renderItem}
                onChange={handleTradingCompanyChange}
            />
        );
    };

    const renderTradingCompanyBids = (position: BwicPosition, tradingState?: TraidingPositionState) => {
        const allCompanyBids = position.bids
            .filter(b => !b.pass && biddingUtils.getBidCompanyIdentifier(b) === tradingState?.tradingCompanyIdentifier)
            .sort((a, b) => b.id - a.id);
        const companyBids = arrayUtils.distinct(allCompanyBids, (bid: Bid) => bid.value);

        return (
            <DropDownList
                className="form-control-sm text-left"
                items={companyBids.map((b: Bid) => ({
                    value: b.id,
                    text: formatUtils.formatBid(b.value),
                    selected: b.id === tradingState?.tradingBidId,
                }))}
                defaultSortingEnabled={false}
                onChange={(item: DropDownListItem) =>
                    dispatch(sellerBiddingActions.tradingPositionBidChange(position.id, +item.value))
                }
            />
        );
    }

    return (
        <Popup title="Trade" modalClass="trade-modal trade-modal-trading" onClose={handleClose}>
            <PopupBody>
                <Table
                    className="data-list-striped"
                    columns={getColumns()}
                    dataItems={positions}
                    createRowCustomKey={(p: BwicPosition) => `Trades-${p.id}`}
                />
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={handleClose}>cancel</button>
                <button
                    className="btn btn-main"
                    onClick={() => dispatch(sellerBiddingActions.tradeConfirm(true))}
                    disabled={!hasSelectedPositions}
                >
                    proceed
                </button>
            </PopupFooter>
            {
                tradingConfirmationVisible &&
                <Confirm
                    TextComponent={() => <>Would you like to complete the trade?<br /> This operation is irreversible.</>}
                    onCancel={() => dispatch(sellerBiddingActions.tradeConfirm(false))}
                    onConfirm={handleConfirmTraded}
                />
            }
        </Popup>
    );
}

export function TradePopup() {
    const tradingPositions = useSelector((s: AppState) => s.sellerBidding.tradingPositions);

    if (!keys(tradingPositions).length) return null;

    return <TradePopupContent tradingPositions={tradingPositions} />
}
